import React from 'react'
import Layout from '../layouts'

const FreeSpeechPage = () => (
  <Layout>
    <div>
      <h1 className="page-title">Free Speech, Sibley, and the ACLU.</h1>
      <div className="announcement">
        <p>
          In December of 2017, the City of Sibley{' '}
          <a href="https://www.aclu-ia.org/sites/default/files/2-1_ex._a.pdf">
            sent a threatening letter to me,
          </a>{' '}
          demanding that I take down this website or else they would sue me. Their
          letter claimed that this website "libels the City of Sibley, interferes
          with recruitment of businesses and new residents, and negatively affects
          property values". The letter also asserted that this website was
          responsible for the clinic in town losing a prospective physician.
        </p>
        <p>
          I'm no lawyer, but I know my rights, and I immediately knew that what
          they were doing was censorship. They were the government, they didn't
          like the criticism I've published online, and they threated me with the
          goal of forcing me to take that criticism down.
        </p>
        <p>
          Fearing a lawsuit that I wouldn't have been able to afford at that time,
          I set up a meeting with one of the two law firms here in town (the other
          law firm being the one that sent the threatening letter.) The lawyer
          agreed with my suspicions and what everybody else had been telling me.
          However, he recommended I speak with someone "outside the city's little
          sphere of influence".
        </p>
        <p>
          I met with the next lawyer in Le Mars, Iowa, but wasn't able to afford
          representation at the time. Running out of both options and time, I
          updated my website to be more fair to the city itself before the ten
          arbitrary days they gave me to comply had passed.
        </p>
        <p>
          Once I updated the website and the city realized I wasn't going to take
          it down, a different attorney from the city's law firm contacted me and
          wanted to chat over coffee. I went, although now I realize I probably
          should not have done so. He was very friendly, telling me he thinks the
          whole situation had been badly handled, but he made it clear that he
          thought I should take down the website because it was hurting the town
          and "we both want what's best" for it. He also told me there was a
          reporter from a newspaper calling around trying to get in touch with me,
          and he didn't think I should talk to them, again because it would damage
          the town. I declined the interview, because I was afraid I'd get sued
          (whether justified or not) if I said something the city didn't like.
        </p>
        <p>
          Of course, I was too naive to realize that the city themselves would
          have no problem talking to the reporter. So she ran her story, and I was
          made out to be the bad guy troublemaker by the city officials she
          interviewed. What's more, a city councilman, the mayor, and the city
          administrator all denied sending a letter to me. They were also quoted
          as saying "there may be legal stuff coming down the road".
        </p>
        <p>
          A few weeks later I received another letter from the law firm, and this
          one was weird. It was the attorney from the original letter writing "on
          his own time" to explain all the reasons he thinks he can sue me, citing
          several Iowa judicial cases and going on about disparaging property. He
          told me I was making a stupid argument and attributing unfounded legal
          arguments to him. The letter ended by saying it wasn't a threat of
          litigation and not intended to deter me from exercising my legal rights.
        </p>
        <p>
          This was around mid-January, 2018 (I received the first letter
          mid-December, 2017). Everything was quiet until I got an email from the
          legal director at the ACLU of Iowa. On the advice of others, I had
          emailed both the ACLU and the EFF, but after over a month with no
          response I had figured they were too busy to look at my case.
        </p>
        <p>
          I spoke with the director and, long story short, she thought what the
          city was doing to me was an egregious violation of my civil rights, and
          the ACLU of Iowa wanted to represent me in a lawsuit against the city.
          Toward the end of February we filed suit in Federal court, and by March
          29th we settled the case after the city agreed to these five terms:
        </p>
        <ol>
          <li>
            They had to agree to a permanent injunction where they can't threaten
            to sue me, or actually sue me, for any website or content I produce
            regarding the town.
          </li>
          <li>They must pay legal damages to me.</li>
          <li>They must pay attorney fees to the ACLU.</li>
          <li>They must write an apology letter to me.</li>
          <li>
            My favorite part, the city's staff and its attorneys must take First
            Amendment training.
          </li>
        </ol>
        <p>
          I'm <em>incredibly</em> impressed with the ACLU's work on this case, and
          I'm eternally grateful to them for what they've done. I know it's not
          typical to file a lawsuit and win a month later, but that just shows how
          blatant the City's attempts to censor me were. I'm super grateful to the
          ACLU for helping me with this, because as I said above, I wouldn't have
          been able to afford an attorney and the city would have gotten away with
          their threat.
        </p>
        <p>
          <a href="https://action.aclu.org/give/support-aclu-iowa?utm_source=shouldyoumovetosibleyia.com">
            Please consider donating to the ACLU of Iowa!
          </a>
        </p>
        <p>
          I've been asked many times by news outlets, radio stations, and readers
          of this website what I would say to the city if given the chance. I
          would say the following:
        </p>
        <p>
          Disagreeing with something that's been written, especially when it's
          critical of your job or work, is absolutely understandable. However,
          that disagreement crosses the line when you threaten the writer with a
          lawsuit, and it's censorship when you're doing it as a government. It
          violates the First Amendment to our Constitution, and creates a chilling
          effect on our democracy.
        </p>
        <p>
          In the future, I would strongly encourage the City to reach out and try
          to start a discussion before they call in the lawyers. I'm severely
          disappointed that they took the legal equivalant of shooting first and
          asking questions later. We both want what's best for the town; I would
          have heard them out.
        </p>
      </div>
    </div>
  </Layout>
)

export default FreeSpeechPage
